import React, { useState } from "react";
import { Card, Space } from "lib";
import { Button, Form, Input, message, Modal } from "antd";
import { Layout } from "layout";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { FormattedMessage,useIntl } from "react-intl";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import ClientGroupDetailMenu from "@components/Admin/ClientGroup/ClientGroupDetailMenu";
import Header from "@components/Admin/ClientGroup/Header";
import AssessmentTemplateMainTable from "@components/Admin/ClientGroup/AssessmentTemplateMainTable";
import AssessmentTemplateGroupTable from "@components/Admin/ClientGroup/AssessmentTemplateGroupTable";

interface Params {
  id: number;
}
interface Props {
  params: Params;
}

enum Mode {
  Main = 0,
  Group = 1,
}

const GroupLevelAssessmentTemplatePage = ({ params }: Props) => {
  const auth = new SubpageAuth(
    SECTION.Admin,
    "ClientGroups",
    "GenerationSettings"
  );
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }
  const groupId = params.id;

  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [mode, setMode] = useState(Mode.Main);

  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
    // await fetchItems(undefined, values.keywords);
  }

  const keywordElement = (
    <Form
      form={form}
      name="horizontal_login"
      layout="inline"
      onFinish={handleKeywordsSearch}
    >
      <Form.Item name="keywords">
        <Input placeholder={StaticKeywords} />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="StaticSearchLabel" />
          </Button>
        )}
      </Form.Item>
    </Form>
  );

  return (
    <Layout title="ClientGroupTemplateNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <Header id={groupId}></Header>
          <ClientGroupDetailMenu id={groupId} />
          {mode == Mode.Main && (
            <Space justify="space-between">
              <Space justify="flex-start">{keywordElement}</Space>
              <Space wrap>
                <Button
                  ghost
                  type="primary"
                  shape="round"
                  onClick={() => {
                    setMode(Mode.Group);
                  }}
                >
                  <FormattedMessage id="ClientGroupTemplateContentGroupButton" />{" "}
                  <RightOutlined />
                </Button>
              </Space>
            </Space>
          )}
          {mode != Mode.Main && (
            <Space justify="space-between">
              <Space justify="flex-start">
                <Button
                  ghost
                  type="primary"
                  shape="round"
                  onClick={() => {
                    setMode(Mode.Main);
                  }}
                >
                  <LeftOutlined />
                </Button>
                {mode == Mode.Group && (
                  <FormattedMessage id="ClientGroupTemplateContentGroupButton" />
                )}
              </Space>
              <Space wrap>{keywordElement}</Space>
            </Space>
          )}
          {mode == Mode.Main && (
            <AssessmentTemplateMainTable
              auth={auth}
              keywords={keywords}
              groupId={groupId}
              modal={modal}
              messageApi={messageApi}
            />
          )}
          {mode == Mode.Group && (
            <AssessmentTemplateGroupTable
              auth={auth}
              keywords={keywords}
              groupId={groupId}
              modal={modal}
              messageApi={messageApi}
            />
          )}
        </Space>
      </Card>
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default GroupLevelAssessmentTemplatePage;

import React from "react";
import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import { MessageInstance } from "antd/lib/message";
import { SubpageAuth } from "@services/auth";
import AssessmentTemplateTable from "./AssessmentTemplateTable";

type HeaderProps = {
  keywords: string | undefined | null;
  groupId: number | undefined;
  auth: SubpageAuth;
  modal: Omit<ModalStaticFunctions, "warn">;
  messageApi: MessageInstance;
};

const AssessmentTemplateGroupTable = ({
  keywords,
  groupId,
  auth,
  modal,
  messageApi,
}: HeaderProps): JSX.Element => {
  return (
    <AssessmentTemplateTable
      keywords={keywords}
      groupId={groupId}
      auth={auth}
      modal={modal}
      messageApi={messageApi}
      isGroup={true}
    />
  );
};
export default AssessmentTemplateGroupTable;

import React, { useEffect, useState } from "react";
import { Table, Space } from "lib";
import { Button, TablePaginationConfig } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  ClientGroupReportGenerationSettingsClient,
  ClientGroupAssessmentTemplatesClient,
  ClientGroupAssessmentTemplateDto,
  ClientGroupsClient,
  SoftDeleteClientGroupAssessmentTemplateCommand,
  ClientGroupGroupReportGenerationSettingsClient,
} from "@api";
import GenerationSettingNestTable from "./GenerationSettingNestTable";
import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import { MessageInstance } from "antd/lib/message";
import { ACTION, SubpageAuth } from "@services/auth";
import { FormattedMessage } from "react-intl";
import { showError } from "@action";
import GenerationSettingCreateModal from "./GenerationSettingCreateModal";
import GeneralFormModal from "@components/GeneralFormModal";

type HeaderProps = {
  keywords: string | undefined | null;
  groupId: number | undefined;
  auth: SubpageAuth;
  modal: Omit<ModalStaticFunctions, "warn">;
  messageApi: MessageInstance;
  isGroup: boolean;
};

const defaultOrderBy = "id";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const AssessmentReportTable = ({
  keywords,
  groupId,
  auth,
  modal,
  messageApi,
  isGroup,
}: HeaderProps): JSX.Element => {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [
    createAssessmentTemplateModalVisible,
    setCreateAssessmentTemplateModalVisible,
  ] = useState(false);
  // const [editModalVisible, setEditModalVisible] = useState(false);

  // AssessmentTemplateId for Creating generation setting modal
  const [createModalAssessmentTemplateId, setCreateModalAssessmentTemplateId] =
    useState(undefined as number | undefined);
  const [
    createModalClientGroupAssessmentTemplateId,
    setCreateModalClientGroupAssessmentTemplateId,
  ] = useState(undefined as number | undefined);
  const [canAddDeleteReportSettings, setCanAddDeleteReportSettings] =
    useState(false);
  const isAllowOptionFullList = auth.can(
    ACTION.Read,
    "AssessmentTemplateOptionFullList"
  );

  const [expended, setExpended] = useState(
    undefined as React.Key[] | undefined
  );

  const clientGroupReportGenerationSettingsClient = isGroup
    ? new ClientGroupGroupReportGenerationSettingsClient()
    : new ClientGroupReportGenerationSettingsClient();
  const clientGroupAssessmentTemplatesClient =
    new ClientGroupAssessmentTemplatesClient();
  const clientGroupsClient = new ClientGroupsClient();

  const [state, setState] = useState({
    items: [] as ClientGroupAssessmentTemplateDto[],
    pagination: defaultPagination,
    loading: false,
  });

  const [assessmentTemplateOptionList, setAssessmentTemplateOptionList] =
    useState([] as any[]);

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "GenerationSettings")) return;

    setState({
      ...state,
      loading: true,
    });
    const res =
      await clientGroupAssessmentTemplatesClient.getClientGroupAssessmentTemplatesWithPagination(
        _keywords ?? keywords,
        groupId,
        undefined,
        params.pagination.current,
        params.pagination.pageSize,
        capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
        (params.sortOrder ?? defaultSortOrder) == "ascend"
      );

    setState({
      ...state,
      items: res.items ?? [],
      pagination: {
        ...params.pagination,
        total: res.totalCount,
      },
      loading: false,
    });
  }

  async function fetchAssessmentTemplateOptionList() {
    const res =
      await clientGroupAssessmentTemplatesClient.getClientGroupAssessmentTemplateOption(
        groupId
      );
    const optionList = res.map((o) => {
      return { value: o.id, label: o.alias };
    });
    setAssessmentTemplateOptionList(optionList);
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function checkCanAddDeleteReportSettings() {
    const res = await clientGroupsClient.checkCanAddDeleteReportSettings(
      groupId
    );

    setCanAddDeleteReportSettings(res);
  }

  const handleAddAssessmentTemplate = async (data: any) => {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticCreatingMessage" />
    );
    const temp = {
      ...data,
      clientGroupId: groupId,
    };
    try {
      await clientGroupAssessmentTemplatesClient.create(temp);
      messageApi.success(<FormattedMessage id="StaticCreateSuccessMessage" />);
      setCreateAssessmentTemplateModalVisible(false);
      await fetchItems();
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  };

  const handleCancelAddAssessmentTemplate = () => {
    setCreateAssessmentTemplateModalVisible(false);
  };

  function OpenModalAddGenerationSetting(
    record: ClientGroupAssessmentTemplateDto
  ) {
    setCreateModalClientGroupAssessmentTemplateId(record.id);
    setCreateModalAssessmentTemplateId(record?.assessmentTemplate?.id);
    setCreateModalVisible(true);
  }

  const handleAddGenerationSetting = async (data: any) => {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticCreatingMessage" />
    );
    try {
      await clientGroupReportGenerationSettingsClient.create(data);
      messageApi.success(<FormattedMessage id="StaticCreateSuccessMessage" />);
      setCreateModalVisible(false);

      setExpended([]); // collapse all expended
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  };

  const handleCancelAddGenerationSetting = () => {
    setCreateModalVisible(false);
  };

  function DeactivateClientGroupAssessmentTemplate(id: number) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmDeactivationMessage" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await clientGroupAssessmentTemplatesClient.disable(
            new SoftDeleteClientGroupAssessmentTemplateCommand({ id })
          );
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: true,
    // },
    {
      // title: "Assessment Template Alias",
      title: <FormattedMessage id="ClientGroupDetailAssessmentTemplateTableHeaderAlias" />,
      dataIndex: ["assessmentTemplate", "alias"],
      key: "assessmentTemplateAlias",
    },
    {
      // title: "Action",
      title: <FormattedMessage id="ClientGroupDetailAssessmentTemplateTableHeaderAction" />,
      render: (text: any, record: ClientGroupAssessmentTemplateDto) => (
        <Space>
          {auth.can(ACTION.Create, "GenerationSettings") ? (
            <Button
              type="primary"
              onClick={() => OpenModalAddGenerationSetting(record)}
            >
              <Space>
                <PlusOutlined />
                {!isGroup ? (
                  <FormattedMessage id="ClientGroupTemplateTableCreateGenerationSettingButton" />
                ) : (
                  <FormattedMessage id="ClientGroupTemplateTableCreateGroupGenerationSettingButton" />
                )}
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Delete, "AssessmentTemplate") ? (
            <Button
              type="primary"
              onClick={() =>
                DeactivateClientGroupAssessmentTemplate(record.id as number)
              }
            >
              <Space>
                <FormattedMessage id="StaticDeactivateButton" />
              </Space>
            </Button>
          ) : null}
        </Space>
      ),
    },
  ];

  const onTableRowExpand = (expanded: boolean, record: any) => {
    const keys = [];
    if (expanded) {
      keys.push(record.id);
    }

    setExpended(keys);
  };

  useEffect(() => {
    fetchItems();
    fetchAssessmentTemplateOptionList();
    checkCanAddDeleteReportSettings();
  }, []);

  return (
    <>
      <Space justify="flex-end">
        {canAddDeleteReportSettings &&
        auth.can(ACTION.Create, "AssessmentTemplate") ? (
          <Button
            type="primary"
            onClick={() => {
              setCreateAssessmentTemplateModalVisible(true);
            }}
          >
            <Space>
              <PlusOutlined />
              <FormattedMessage id="ClientGroupTemplateContentAddButton" />
            </Space>
          </Button>
        ) : null}
      </Space>
      <Table
        bordered
        dataSource={[...state.items]}
        columns={canAddDeleteReportSettings ? columns : columns.slice(0, -1)}
        rowKey={(r) => r.id}
        pagination={state.pagination}
        loading={state.loading}
        onChange={handleTableChange}
        expandable={{
          expandedRowKeys: expended,
          onExpand: onTableRowExpand,
          expandedRowRender: (
            record: ClientGroupAssessmentTemplateDto,
            index,
            indent,
            expanded
          ) => (
            <GenerationSettingNestTable
              clientGroupId={groupId}
              clientgroupassessmentTemplateId={record.id}
              expanded={expanded}
              modal={modal}
              messageApi={messageApi}
              auth={auth}
              canAddDeleteReportSettings={canAddDeleteReportSettings}
              clientGroupReportGenerationSettingsClient={
                clientGroupReportGenerationSettingsClient
              }
            />
          ),
        }}
      />
      <GenerationSettingCreateModal
        handleAction={handleAddGenerationSetting}
        handleCancelAction={handleCancelAddGenerationSetting}
        visible={createModalVisible}
        clientGroupId={groupId as number}
        clientGroupAssessmentTemplateId={
          createModalClientGroupAssessmentTemplateId
        }
        assessmentTemplateId={createModalAssessmentTemplateId}
        isGroup={isGroup}
      />
      <GeneralFormModal
        visible={createAssessmentTemplateModalVisible}
        handleAction={handleAddAssessmentTemplate}
        handleCancelAction={handleCancelAddAssessmentTemplate}
        titleId={"ClientGroupTemplateModalAddTitle"}
        editableFields={[
          {
            name: "assessmentTemplateId",
            labelId: "ClientGroupTemplateStaticAliasLabel",
            type: "select",
            options: assessmentTemplateOptionList,
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientGroupTemplateStaticMissingAliasRule" />
                ),
              },
            ],
          },
        ]}
      />
    </>
  );
};
export default AssessmentReportTable;

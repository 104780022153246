import { showError } from "@action";
import {
  ClientGroupGroupReportGenerationSettingDto,
  ClientGroupGroupReportGenerationSettingsClient,
  ClientGroupReportGenerationSettingDto,
  ClientGroupReportGenerationSettingsClient,
  DeleteClientGroupGroupReportGenerationSettingCommand,
  DeleteClientGroupReportGenerationSettingCommand,
  UpdateClientGroupReportGenerationSettingCommand,
} from "@api";
import { ACTION, SubpageAuth } from "@services/auth";
import { Button, Switch, Table, TablePaginationConfig } from "antd";
import { MessageInstance } from "antd/lib/message";
import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import { Space } from "lib";

import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

type HeaderProps = {
  auth: SubpageAuth;
  clientGroupId: number | undefined;
  clientgroupassessmentTemplateId: number | undefined;
  // columns: any;
  expanded: boolean;
  modal: Omit<ModalStaticFunctions, "warn">;
  messageApi: MessageInstance;
  canAddDeleteReportSettings: boolean;
  clientGroupReportGenerationSettingsClient:
    | ClientGroupReportGenerationSettingsClient
    | ClientGroupGroupReportGenerationSettingsClient;
};

const defaultOrderBy = "id";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["bottomCenter"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const GenerationSettingNestTable = ({
  auth,
  clientGroupId,
  clientgroupassessmentTemplateId,
  // columns,
  expanded,
  modal,
  messageApi,
  canAddDeleteReportSettings,
  clientGroupReportGenerationSettingsClient,
}: HeaderProps): JSX.Element => {
  const isGroup =
    clientGroupReportGenerationSettingsClient instanceof
    ClientGroupGroupReportGenerationSettingsClient;

  const [state, setState] = useState({
    items: [] as
      | ClientGroupReportGenerationSettingDto[]
      | ClientGroupGroupReportGenerationSettingDto[],
    pagination: defaultPagination,
    loading: false,
  });

  //
  async function fetchItems(params: any = { pagination: state.pagination }) {
    setState({
      ...state,
      loading: true,
    });
    if (!isGroup) {
      const res =
        await clientGroupReportGenerationSettingsClient.getClientGroupReportGenerationSettingsWithPagination(
          undefined,
          clientGroupId,
          clientgroupassessmentTemplateId,
          params.pagination.current,
          params.pagination.pageSize,
          capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
          (params.sortOrder ?? defaultSortOrder) == "ascend"
        );

      setState({
        ...state,
        items:
          (res.items as ClientGroupReportGenerationSettingDto[]) ||
          ([] as ClientGroupReportGenerationSettingDto[]),
        pagination: {
          ...params.pagination,
          total: res.totalCount,
        },
        loading: false,
      });
    } else {
      const res =
        await clientGroupReportGenerationSettingsClient.getClientGroupGroupReportGenerationSettingsWithPagination(
          undefined,
          clientGroupId,
          clientgroupassessmentTemplateId,
          params.pagination.current,
          params.pagination.pageSize,
          capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
          (params.sortOrder ?? defaultSortOrder) == "ascend"
        );

      setState({
        ...state,
        items:
          (res.items as ClientGroupGroupReportGenerationSettingDto[]) ||
          ([] as ClientGroupGroupReportGenerationSettingDto[]),
        pagination: {
          ...params.pagination,
          total: res.totalCount,
        },
        loading: false,
      });
    }
  }

  async function handleEditGenerationSetting(
    id: number,
    isReportAutoGenerated: boolean
  ) {
    if (isGroup) return;

    const hide = messageApi.loading(
      <FormattedMessage id="StaticUpdatingMessage" />
    );
    try {
      await clientGroupReportGenerationSettingsClient.update(
        new UpdateClientGroupReportGenerationSettingCommand({
          id,
          isReportAutoGenerated,
        })
      );
      messageApi.success(<FormattedMessage id="StaticUpdateSuccessMessage" />);
      await fetchItems();
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  function handleDeleteGenerationSetting(id: number) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmDeleteMessage" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticDeletingMessage" />
        );
        try {
          await clientGroupReportGenerationSettingsClient.detele(
            !isGroup
              ? new DeleteClientGroupReportGenerationSettingCommand({ id })
              : new DeleteClientGroupGroupReportGenerationSettingCommand({ id })
          );
          messageApi.success(
            <FormattedMessage id="StaticDeleteSuccessMessage" />
          );
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  const columns = [
    {
      // title: "ID",
      title: <FormattedMessage id="ClientGroupGenerationSettingNestTableHeaderId" />,
      dataIndex: "id",
      key: "id",
    },
    {
      // title: "Report Template",
      title: <FormattedMessage id="ClientGroupGenerationSettingNestTableHeaderReportTemplate" />,
      dataIndex: isGroup ? "groupReportTemplateName" : "reportTemplateName",
      key: isGroup ? "groupReportTemplateName" : "reportTemplateName",
      sorter: true,
    },
    {
      // title: "Report Language",
      title: <FormattedMessage id="ClientGroupGenerationSettingNestTableHeaderReportLanuage" />,
      dataIndex: isGroup ? "groupReportLanguage" : "reportLanguage",
      key: isGroup ? "groupReportLanguage" : "reportLanguage",
      sorter: true,
    },
    {
      // title: "Norm Table",
      title: <FormattedMessage id="ClientGroupGenerationSettingNestTableHeaderNormTable" />,
      dataIndex: "normTableName",
      key: "normTableName",
      sorter: true,
    },
    {
      // title: "Is Auto Generated",
      title: <FormattedMessage id="ClientGroupGenerationSettingNestTableHeaderIsAutoGenerated" />,
      dataIndex: "isAutoGenerated",
      key: "isAutoGenerated",
      sorter: true,
      render: (b: boolean, record: ClientGroupReportGenerationSettingDto) => (
        <Space>
          {auth.can(ACTION.Edit, "GenerationSettings") ? (
            <Switch
              checked={b}
              onChange={(checked) =>
                handleEditGenerationSetting(record.id as number, checked)
              }
            ></Switch>
          ) : (
            <>{String(b)}</>
          )}
        </Space>
      ),
    },
    {
      // title: "Action",
      title: <FormattedMessage id="ClientGroupGenerationSettingNestTableHeaderIdAction" />,
      render: (text: any, record: ClientGroupReportGenerationSettingDto) => (
        <Space>
          {auth.can(ACTION.Delete, "GenerationSettings") ? (
            <Button
              type="primary"
              onClick={() => handleDeleteGenerationSetting(record.id as number)}
            >
              <Space>
                <FormattedMessage id="StaticDeleteButton" />
              </Space>
            </Button>
          ) : null}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (expanded) fetchItems();
  }, [expanded]);

  if (isGroup) {
    columns.splice(4, 1);
  }

  return (
    <Table
      dataSource={[...state.items]}
      columns={canAddDeleteReportSettings ? columns : columns.slice(0, -1)}
      rowKey={clientgroupassessmentTemplateId?.toString()}
      pagination={state.pagination}
      loading={state.loading}
      onChange={handleTableChange}
    />
  );
};

export default GenerationSettingNestTable;

import React, { useState, useEffect } from "react";
import { Space } from "lib";
import { Form, Modal, Select, Switch } from "antd";
import {
  GroupReportGenerationSettingsClient,
  GroupReportGenerationSettingsDto,
  ReportGenerationSettingsClient,
  ReportGenerationSettingsDto,
} from "@api";
import { showError } from "@action";
import { FormattedMessage } from "react-intl";
import { useForm } from "antd/lib/form/Form";
const { Option } = Select;
import { getIsClientAdmin } from "@services/auth";

interface ModalProps {
  visible: boolean;
  handleAction: (data: any) => Promise<void>;
  handleCancelAction: () => void;
  clientGroupId: number;
  clientGroupAssessmentTemplateId: number | undefined;
  assessmentTemplateId: number | undefined;
  isGroup: boolean;
}

const GenerationSettingCreateModal: React.FC<ModalProps> = ({
  visible,
  handleAction,
  handleCancelAction,
  clientGroupId,
  clientGroupAssessmentTemplateId,
  assessmentTemplateId,
  isGroup,
}) => {
  const [form] = useForm();

  const client = isGroup
    ? new GroupReportGenerationSettingsClient()
    : new ReportGenerationSettingsClient();

  const [isDisableScoringKeySet, setIsDisableScoringKeySet] = useState(true);
  const [isDisableSelectNormTable, setIsDisableSelectNormTable] =
    useState(true);
  const [isDisableSelectReportLanguage, setIsDisableSelectReportLanguage] =
    useState(true);

  const [loading, setLoading] = useState(false);

  const [reportLanguageData, setReportLanguageData] = useState([] as string[]);
  const [reportTemplateData, setReportTemplateData] = useState([] as string[]);
  const [reportScoringKeySetData, setReportScoringKeySetData] = useState(
    [] as string[]
  );
  const [reportNormData, setReportNormData] = useState([] as string[]);
  const [optionListPool, setOptionListPool] = useState({} as any);

  const [reportLanguage, setReportLanguage] = React.useState("");
  const [reportTemplate, setReportTemplate] = React.useState("");
  const [scoringKeySetName, setScoringKeySetName] = React.useState("");
  const [reportNorm, setReportNorm] = useState("");

  const fetchItems = async () => {
    setLoading(true);
    try {
      if (client instanceof GroupReportGenerationSettingsClient) {
        const res = await client.getGroupReportGenerationSettings(
          clientGroupId,
          assessmentTemplateId
        );
        groupby(res);
      } else {
        const res = await client.getReportGenerationSettings(
          clientGroupId,
          assessmentTemplateId
        );
        groupby(res);
      }
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  const groupby = (
    array: (ReportGenerationSettingsDto | GroupReportGenerationSettingsDto)[]
  ) => {
    const reportTemplateNameTemp: string[] = [];

    // tree object
    const result = array.reduce((group: any, setting) => {
      const { normTable, scoringKeySet } = setting;
      const reportTemplate =
        setting instanceof GroupReportGenerationSettingsDto
          ? setting.groupReportTemplate
          : setting.reportTemplate;

      const reportLanguage = reportTemplate?.preferredLanguageDisplayName;
      const reportTemplateName = reportTemplate?.displayName;
      const normTableName = normTable?.name;
      const scoringKeyName = scoringKeySet?.name;

      if (
        reportLanguage &&
        reportTemplateName &&
        normTableName &&
        scoringKeyName
      ) {
        if (!group[reportTemplateName]) {
          group[reportTemplateName] = {};

          reportTemplateNameTemp.push(reportTemplateName);
        }
        if (!group[reportTemplateName][reportLanguage]) {
          group[reportTemplateName][reportLanguage] = {};
        }

        if (getIsClientAdmin()) {
          if (!group[reportTemplateName][reportLanguage][normTableName]) {
            group[reportTemplateName][reportLanguage][normTableName] =
              group[reportTemplateName][reportLanguage][normTableName] ?? [];
            group[reportTemplateName][reportLanguage][normTableName].push(
              setting
            );
          }
        } else {
          if (!group[reportTemplateName][reportLanguage][scoringKeyName]) {
            group[reportTemplateName][reportLanguage][scoringKeyName] = {};
          }

          if (
            !group[reportTemplateName][reportLanguage][scoringKeyName][
              normTableName
            ]
          ) {
            group[reportTemplateName][reportLanguage][scoringKeyName][
              normTableName
            ] =
              group[reportTemplateName][reportLanguage][scoringKeyName][
                normTableName
              ] ?? [];
            group[reportTemplateName][reportLanguage][scoringKeyName][
              normTableName
            ].push(setting);
          }
        }
      }
      return group;
    }, {});

    setReportTemplateData(reportTemplateNameTemp);
    setOptionListPool(result);
  };

  async function handleSelectReportLang(value: string) {
    setReportLanguage(value);

    const temp = Object.keys(optionListPool[reportTemplate][value]);
    if (getIsClientAdmin()) {
      setReportNormData(temp);
      if (isDisableSelectNormTable) {
        setIsDisableSelectNormTable(false);
      } else {
        setReportNorm("");
        form.setFieldsValue({
          normTableName: undefined,
        });
      }
    } else {
      setReportScoringKeySetData(temp);
      if (isDisableScoringKeySet) {
        setIsDisableScoringKeySet(false);
      } else {
        setScoringKeySetName("");
        setReportNorm("");
        form.setFieldsValue({
          scoringKeySetName: undefined,
          normTableName: undefined,
        });
      }
      setIsDisableSelectNormTable(true);
    }
  }

  async function handleSelectReportTemplate(value: string) {
    setReportTemplate(value);

    const temp = Object.keys(optionListPool[value]);
    setReportLanguageData(temp);
    if (isDisableSelectReportLanguage) {
      setIsDisableSelectReportLanguage(false);
    } else {
      setReportLanguage("");
      setScoringKeySetName("");
      setReportNorm("");
      form.setFieldsValue({
        reportLanguage: undefined,
        scoringKeySetName: undefined,
        normTableName: undefined,
      });
      setIsDisableScoringKeySet(true);
      setIsDisableSelectNormTable(true);
    }
  }

  async function handleSelectScoringKeySet(value: string) {
    setScoringKeySetName(value);

    const temp = Object.keys(
      optionListPool[reportTemplate][reportLanguage][value]
    );
    setReportNormData(temp);
    if (isDisableSelectNormTable) {
      setIsDisableSelectNormTable(false);
    } else {
      setReportNorm("");
      form.setFieldsValue({
        normTableName: undefined,
      });
    }
  }

  async function handleSelectNormTable(value: string) {
    setReportNorm(value);
  }

  const handleOK = async () => {
    try {
      const values = await form.validateFields();

      const selectedGenerationSetting:
        | ReportGenerationSettingsDto
        | GroupReportGenerationSettingsDto = getIsClientAdmin()
        ? optionListPool[reportTemplate][reportLanguage][reportNorm][0]
        : optionListPool[reportTemplate][reportLanguage][scoringKeySetName][
            reportNorm
          ][0];

      if (values) {
        if (
          selectedGenerationSetting instanceof GroupReportGenerationSettingsDto
        ) {
          await handleAction({
            groupReportTemplateId:
              selectedGenerationSetting.groupReportTemplate?.id,
            normTableId: selectedGenerationSetting.normTable?.id,
            clientGroupAssessmentTemplateId: clientGroupAssessmentTemplateId,
            scoringKeySetId: selectedGenerationSetting.scoringKeySet?.id,
          });
        } else {
          await handleAction({
            isReportAutoGenerated: values.isReportAutoGenerated,
            reportTemplateId: selectedGenerationSetting.reportTemplate?.id,
            normTableId: selectedGenerationSetting.normTable?.id,
            clientGroupAssessmentTemplateId: clientGroupAssessmentTemplateId,
            scoringKeySetId: selectedGenerationSetting.scoringKeySet?.id,
          });
        }

        form.resetFields();
      }
    } catch (err) {
      /* form error block*/
    }
  };

  const handleCancel = () => {
    resetStateValues();
    handleCancelAction();
  };

  async function resetStateValues() {
    form.resetFields();
    setIsDisableScoringKeySet(true);
    setIsDisableSelectNormTable(true);
    setIsDisableSelectReportLanguage(true);
  }

  useEffect(() => {
    if (visible) fetchItems();
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title={
        <FormattedMessage id="ClientGroupTemplateModalCreateGenerationSettingTitle" />
      }
      okText={<FormattedMessage id="StaticCreateButton" />}
      cancelText={<FormattedMessage id="StaticCancelButton" />}
      onOk={handleOK}
      onCancel={handleCancel}
      destroyOnClose={true}
    >
      <Space direction="vertical" size="middle">
        <Form
          labelWrap
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
        >
          {isGroup || (
            <Form.Item
              name="isReportAutoGenerated"
              label={
                <FormattedMessage id="ClientGroupTemplateFormAutoReportLabel" />
              }
              valuePropName="checked"
              initialValue={true}
            >
              <Switch />
            </Form.Item>
          )}
          <Form.Item
            name="reportTemplateName"
            label={
              <FormattedMessage id="ReportReportTemplateStaticSelectLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ReportReportTemplateStaticMissingSelectRule" />
                ),
              },
            ]}
          >
            <Select
              placeholder={
                <FormattedMessage id="ReportReportTemplateStaticSelectPlaceholder" />
              }
              onChange={handleSelectReportTemplate}
              style={{ width: "240px" }}
              loading={loading}
            >
              {reportTemplateData.map((x, i) => (
                <Option key={i} value={x}>
                  {x}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="reportLanguage"
            label={<FormattedMessage id="LanguageLanguageStaticSelectLabel" />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="LanguageLanguageStaticMissingSelectRule" />
                ),
              },
            ]}
          >
            <Select
              placeholder={
                <FormattedMessage id="LanguageLanguageStaticSelectPlaceholder" />
              }
              onChange={handleSelectReportLang}
              style={{ width: "240px" }}
              loading={loading}
              disabled={isDisableSelectReportLanguage}
            >
              {reportLanguageData.map((x, i) => (
                <Option key={i} value={x}>
                  {x}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {getIsClientAdmin() == true ? null : (
            <Form.Item
              name="scoringKeySetName"
              label={
                <FormattedMessage id="ReportScoringKeySetStaticSelectLabel" />
              }
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="ReportScoringKeySetStaticMissingSelectRule" />
                  ),
                },
              ]}
            >
              <Select
                placeholder={
                  <FormattedMessage id="ReportScoringKeySetStaticSelectPlaceholder" />
                }
                onChange={handleSelectScoringKeySet}
                disabled={isDisableScoringKeySet}
                loading={loading}
                style={{ width: "240px" }}
              >
                {reportScoringKeySetData.map((x, i) => (
                  <Option key={i} value={x}>
                    {x}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="normTableName"
            label={<FormattedMessage id="ReportNormStaticSelectLabel" />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ReportNormStaticMissingSelectRule" />
                ),
              },
            ]}
          >
            <Select
              placeholder={
                <FormattedMessage id="ReportNormStaticSelectPlaceholder" />
              }
              onChange={handleSelectNormTable}
              disabled={isDisableSelectNormTable}
              style={{ width: "240px" }}
              loading={loading}
            >
              {reportNormData.map((x, i) => (
                <Option key={i} value={x}>
                  {x}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};

export default GenerationSettingCreateModal;
